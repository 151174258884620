import { 
    Container, Tabs, TabList, TabPanels, Tab, TabPanel, 
    Divider, HStack, Flex, Spacer, IconButton, Input, Button,
    Box, Text, VStack, useToast, Switch, Card, CardHeader, CardBody, Center,
    Slider, SliderTrack, SliderFilledTrack, SliderThumb
} from "@chakra-ui/react";

import LoadingOverlay from "../components/LoadingOverlayComponent";
import { useState, useRef, useCallback, useEffect } from 'react';
import { FaPaperclip, FaPlus } from "react-icons/fa6";
import { DeleteIcon, ArrowForwardIcon, ArrowBackIcon, RepeatIcon} from '@chakra-ui/icons';
import { IoMdDownload, IoIosSend, IoMdSend } from 'react-icons/io';
import { newTheme, documentSummaryMDTheme } from '../components/mdstyle';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import Markdown from "react-markdown";

import { 
    postMultitaskSummaryDocuments,
    postMultitaskDownloadReport
} from "../Api";

import { usePageColor } from "../contexts/ColorContext";
import { scrollToBottom } from "../components/animation";

const PlaygroundPage = () => {

    const colors = usePageColor('summary');

    const [documentSummaryInput, setDocumentSummaryInput] = useState('');
    const [documentSummaryFile, setDocumentSummaryFile] = useState([{fileName: '', file: null, id: Date.now()}]);
    const [documentSummaryResponse, setDocumentSummaryResponse] = useState({});
    const [isFileInputAppearing, setIsFileInputAppearing] = useState(false);
    const [deletingFileIndices, setDeletingFileIndices] = useState([]);
    const [isDocumentSummaryTitleFixed, setIsDocumentSummaryTitleFixed] = useState(false);

    const [isQueryLoading, setIsQueryLoading] = useState(false);
    const [isButtonsDisappeared, setIsButtonsDisappeared] = useState(false);
    const [isNode2CurrentResponseBeing, setNode2CurrentResponseBeing] = useState(false);
    const [thisFileDeleteIcons, setThisFileDeleteIcons] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);

    const documentSummaryInputFileRef = useRef(null);
    const messagesEndRef = useRef(null);
    const toast = useToast();
    const [documentGuideQuery, setDocumentGuideQuery] = useState("");
    const [temperature, setTemperature] = useState(0.50); 

    const setInputValue = (value) => {
        setDocumentSummaryInput(value);
    };

    const getInputFileValue = () => {
        return documentSummaryFile;
    };

    const modalFileInputButtonClick = () => {
        const currentRef = documentSummaryInputFileRef.current;
        if (currentRef) {
            currentRef.click();
        }
    };

    const handleFileChange = useCallback((e) => {
        const files = Array.from(e.target.files);
        
        if (files.length > 3) {
            toast({
                title: 'Failed',
                description: '파일은 최대 3개까지 올릴 수 있습니다.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            e.target.value = '';
            return;
        }

        
        let fileData = [];
        files.map((file, index) => {
                let fileOneData = {
                    fileName: file.name,
                    file: file,
                    id: `${Date.now()}-${index}`,
                }
                fileData.push(fileOneData);
        })
        setDocumentSummaryFile(fileData);
        setIsFileInputAppearing(true);
    }, []);

    useEffect(()=>{
        scrollToBottom(messagesEndRef);
    }, [chatMessages])

    useEffect(() => {
        if (isFileInputAppearing) {
            const timer = setTimeout(() => {
                setIsFileInputAppearing(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [isFileInputAppearing]);

    useEffect(()=>{
        setDocumentSummaryInput('');
    }, [isDocumentSummaryTitleFixed])


    
    const handleDeleteButtonClick = useCallback((index) => {
        setDeletingFileIndices(prev => [...prev, index]);
        setTimeout(() => {
            setDocumentSummaryFile(prev => prev.filter((_, i) => i !== index));
            setDeletingFileIndices(prev => prev.filter(i => i !== index));
        }, 500);
    }, [documentSummaryInputFileRef]);


    const handleDocumentSummary = useCallback(async (userPrompt, documentGuideQuery, temperature, userFile) => {
        
        if (userPrompt === '' && !isDocumentSummaryTitleFixed) {
            toast({
                title: 'Failed',
                description: '제목은 필수입니다. 제목을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }

        try {
            setIsQueryLoading(true);
            setIsButtonsDisappeared(true);
            setNode2CurrentResponseBeing(true);
            setDocumentSummaryResponse({});
            toast({
                title: 'Processing',
                description: '해당 작업을 처리하는데 오랜 시간이 소요될 수 있습니다. 페이지를 이탈하면 결과가 손실될 수 있습니다.',
                status: 'loading',
                isClosable: true,
                position: 'top-right'
            }); 

            const data = await postMultitaskSummaryDocuments(userPrompt, documentGuideQuery, temperature, userFile, isDocumentSummaryTitleFixed);     
            if (data?.error) {
                toast({
                    title: 'Failed',
                    description: data.error,
                    status: 'error',
                    isClosable: true,
                });
                setNode2CurrentResponseBeing(false);
                return;
            } 
            console.log("==============================================");
            console.log(data);
            console.log("==============================================");
            setDocumentSummaryResponse(data);
            return;
        } catch (error) {
            toast({
                title: 'Failed',
                description: error.message ? error.message : '오류가 일어났습니다. 시스템 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            setNode2CurrentResponseBeing(false);
            return;
        } finally {
            setIsQueryLoading(false);
            setIsButtonsDisappeared(false);
        }
    }, [isDocumentSummaryTitleFixed, isNode2CurrentResponseBeing])

    const handleSendButtonClick = useCallback(async() => {
        let userPrompt = '';
        let userFile = null;
        
        userPrompt = isDocumentSummaryTitleFixed? '' : documentSummaryInput;
        userFile = documentSummaryFile;

        if (userPrompt === '' && !isDocumentSummaryTitleFixed) {
            toast({
                title: 'Failed',
                description: '프롬프트는 필수입니다. 프롬프트를 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }

        if (userPrompt === '' && !isDocumentSummaryTitleFixed) {
            toast({
                title: 'Failed',
                description: '프롬프트는 필수입니다. 프롬프트를 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }
    
        if (userFile.file === null) {
            toast({
                title: 'Failed',
                description: '파일은 필수입니다. 파일을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }  

        await handleDocumentSummary(userPrompt, documentGuideQuery, temperature, userFile);
        return;
    }, [isDocumentSummaryTitleFixed, documentSummaryInput, documentGuideQuery, temperature, documentSummaryFile, toast, handleDeleteButtonClick]);

    const allowNodeIndexTwoThisFileInputLeftIcon = (index) => {
        setThisFileDeleteIcons(prev => prev.map((icon, i) => i === index ? true : icon));
    };
    
    const resetFileDeleteIcons = (index) => {
        setThisFileDeleteIcons(prev => prev.map((icon, i) => i === index ? false : icon));
    };

    useEffect(() => {
        const fileValues = documentSummaryFile;
        setThisFileDeleteIcons(new Array(fileValues.length).fill(false));
    }, [documentSummaryFile]);

    const newDocumentSummary = useCallback(() => {
        setDocumentSummaryInput('');
        setDocumentSummaryFile([{fileName: '', file: null, id: Date.now()}]);
        setDocumentSummaryResponse({});
        setNode2CurrentResponseBeing(false);
        documentSummaryInputFileRef.current.value = null;
    }, [documentSummaryInputFileRef]);

    const renderPlacholderForDocumentSummary = () =>{
        if (isDocumentSummaryTitleFixed) {
            return "자동 제목 생성이 될 예정입니다."   
        } else {
            return "제목을 입력해주세요."
        }
    }

    const handlePostMultitaskDownloadReport = useCallback(async ()=>{

        if (!documentSummaryResponse.report_title || !documentSummaryResponse.markdown_content) {
            toast({
                title: 'Failed',
                description: '요약문서가 생성중이니 잠시만 기다려주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }

        try {
            const reportTitle = documentSummaryResponse.report_title;
            const markdownContent = documentSummaryResponse.markdown_content;

            await postMultitaskDownloadReport(reportTitle, markdownContent);
        } catch (error) {
            toast({
                title: 'Failed',
                description: error.message ? error.message : '오류가 일어났습니다. 시스템 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, [documentSummaryResponse])

    return (
        <Container
            maxW="100%" 
            minH="100vh"
            bg={colors.ContainerMainBg}
            py='4'
        >
            <HStack maxW='100%'>
                <Container
                    maxW="container.sm"
                    borderRadius="16px"
                    p='4'
                    minH='90vh'
                    bg={colors.ContainerCenterBg}
                    position="relative"
                >
                    <LoadingOverlay isLoading={isQueryLoading} bgColor={colors.ContainerCenterBg}/>
                    <Flex direction='column' minH='85vh'>
                    <Tabs variant="unstyled" position='relative' >
                        <TabList 
                                style={{
                                    animation: isQueryLoading ? 'fadeOutUp 1s ease' : 
                                            !isQueryLoading && !isButtonsDisappeared ? 'fadeInDown 1s ease' : 'none'
                                }}
                                mb={2}
                        >
                            <Tab 
                                    borderRadius='20px'
                                    border='2px solid transparent'
                                    _selected={{ bg: '#4CAF50', color: 'white', fontWeight: 'bold' }}
                                    transition='all 0.3s ease'
                                    mr='3'
                                >
                                    보도자료
                            </Tab>
                        </TabList>

                        <Divider mt={3} />

                        <TabPanels> 
                            <TabPanel 
                                    ref={messagesEndRef}
                                    style={{
                                        animation: isQueryLoading ? 'fadeOutDown 1s ease' : 
                                                !isQueryLoading && !isButtonsDisappeared ? 'fadeInUp 1s ease' : 'none'
                                    }}
                            >                                
                                <VStack align="stretch" spacing={4} mb={4}>
                                    <Card borderRadius='16'>
                                        <CardHeader>
                                            <HStack  spacing={4}>
                                                <Switch 
                                                    id='documentSummarySwitch'
                                                    onChange={()=>{
                                                        setIsDocumentSummaryTitleFixed(prev=>!prev)
                                                    }}
                                                    />
                                                <Text>
                                                    {isDocumentSummaryTitleFixed? "자동 제목 활성화!":"활성화되면 HCX가 자동으로 제목을 생성해줍니다."}
                                                </Text>
                                            </HStack>
                                            
                                        </CardHeader>
                                        <CardBody>
                                            <Input 
                                                onChange={(e)=>{
                                                    setDocumentSummaryInput(e.target.value)
                                                }}
                                                borderRadius='13'
                                                placeholder={renderPlacholderForDocumentSummary()}
                                                value={documentSummaryInput}
                                                readOnly={isDocumentSummaryTitleFixed? true: false}
                                                height='50px'
                                                bg={isDocumentSummaryTitleFixed? colors.InputBoxBg:colors.ContainerCenterBg}
                                                onKeyDown={(e) => {
                                                    if(e.key === 'Enter') {
                                                        e.preventDefault();
                                                        handleSendButtonClick();
                                                    }                     
                                                }}
                                                mb='10px' 
                                            />    
                                            <Input
                                                placeholder='보도자료 작성 가이드를 입력해주세요.'
                                                
                                                borderRadius='13px'
                                                height='50px'
                                                value={documentGuideQuery}
                                                onChange={(e) => setDocumentGuideQuery(e.target.value)}
                                                mb='10px' 
                                                >    
                                            </Input> 
                                            <Box display="flex" justifyContent="space-between" mb={2}>
                                                <Text>Temperature
                                                    <Text as="span" fontSize="sm" color="gray.500" ml={1}>
                                                    (높이면 자유도가 증가합니다)
                                                    </Text>
                                                </Text>
                                                <Text>{temperature.toFixed(2)}</Text>
                                            </Box>
                                            <Slider
                                                aria-label="temperature-slider"
                                                defaultValue={0.50} // 기본값
                                                min={0.01}
                                                max={1.00}
                                                step={0.01}
                                                onChange={(val) => setTemperature(val)} // 슬라이더 값 변경 시 상태 업데이트
                                            >
                                                <SliderTrack bg="gray.200">
                                                    <SliderFilledTrack bg="linear-gradient(to right, #00BFFF, #00FF7F)" />
                                                </SliderTrack>
                                                <SliderThumb boxSize={6} />
                                            </Slider>
                                        </CardBody>                                                                        
                                    </Card>
                                    <Card borderRadius='16'>
                                        <CardHeader>
                                            <HStack>
                                                <Text>파일 업로드</Text>
                                                <Spacer />
                                                <IconButton 
                                                    icon={<FaPlus />}
                                                    onClick={modalFileInputButtonClick}
                                                    borderRadius='13'
                                                />
                                            </HStack>
                                        </CardHeader>
                                        <CardBody>
                                            {getInputFileValue().length > 0 && getInputFileValue()[0].file && (
                                            <VStack>                                            
                                                {getInputFileValue().map((fileValue, index) => (
                                                    <Button
                                                        key={fileValue.id}
                                                        borderRadius='13px'
                                                        p='2'
                                                        minW='100px'
                                                        leftIcon={thisFileDeleteIcons[index] ? <DeleteIcon /> : <FaPaperclip />}
                                                        _hover={{
                                                        bg: 'red.500',
                                                        color: 'white',
                                                        transform: 'translateX(-10px)'
                                                        }}
                                                        onMouseEnter={() => allowNodeIndexTwoThisFileInputLeftIcon(index)}
                                                        onMouseLeave={() => resetFileDeleteIcons(index)}
                                                        onClick={() => handleDeleteButtonClick(index)}
                                                        transition="all 0.5s ease"                            
                                                        opacity={deletingFileIndices.includes(index) ? 0 : 1}                            
                                                        style={{
                                                            animation: isFileInputAppearing ? 'fadeInRight 0.5s ease' : 
                                                                        deletingFileIndices.includes(index) ? 'fadeOutLeft 0.5s ease' : 'none'
                                                        }}
                                                        whiteSpace="nowrap"
                                                        overflow="hidden"
                                                        textOverflow="ellipsis"
                                                        display="block"
                                                        maxW='100%'
                                                    >
                                                    {`${fileValue.fileName}`}
                                                    </Button>
                                                ))}
                                            </VStack>
                                            )}
                                        </CardBody>
                                    </Card>
                                </VStack>
                                <Box>
                                    {!isNode2CurrentResponseBeing && (
                                        <Flex>
                                            <Spacer />
                                            <Button
                                                borderRadius='12'
                                                leftIcon={<IoMdSend/>}
                                                colorScheme='green'
                                                onClick={handleSendButtonClick}
                                            >
                                                보도자료 생성
                                            </Button>
                                        </Flex>
                                        )}
                                    {(isNode2CurrentResponseBeing && (
                                        <Flex>
                                            <Button
                                                borderRadius='12'
                                                leftIcon={<RepeatIcon/>}
                                                onClick={()=>{
                                                    newDocumentSummary();                                                        
                                                }}
                                            >
                                                초기화
                                            </Button>
                                            <Spacer />
                                            <Button
                                                borderRadius='12'
                                                leftIcon={<IoMdSend/>}
                                                colorScheme='green'
                                                onClick={handleSendButtonClick}
                                            >
                                                보도자료 재생성
                                            </Button>
                                        </Flex>
                                    ))}                                       
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    <Spacer />             
                    <Input
                        type="file"
                        ref={documentSummaryInputFileRef}
                        hidden
                        accept=".pdf, .hwp, .txt, .csv"
                        onChange={handleFileChange}
                        multiple
                    />
                    </Flex>
                </Container>

                {isNode2CurrentResponseBeing && (
                    <Container
                        borderRadius="16px"
                        p='4'
                        h='90vh'
                        bg={colors.ContainerCenterBg}
                        minW='container.xl'
                        overflow='auto'
                    >

                        <Card borderRadius='16'>
                            <CardBody>
                                <Flex>
                                    <Center 
                                        fontSize='2xl' 
                                        fontWeight='bold'
                                        maxW='70%'
                                        overflow='hidden'
                                        whiteSpace='nowrap'
                                        textOverflow='ellipsis'
                                        display="block"                                                                          
                                    >
                                        {documentSummaryResponse.report_title? documentSummaryResponse.report_title: '파일 제목이 들어갈 예정입니다.'}                    
                                    </Center>
                                    <Spacer />
                                    <HStack>
                                        <IconButton 
                                            icon={<IoMdDownload />}
                                            borderRadius='13px'
                                            bg={colors.NavigationBarBg}
                                            onClick={handlePostMultitaskDownloadReport}
                                        />
                                    </HStack>
                                </Flex>                                
                            </CardBody>
                        </Card>
                        <Box mt='4'>
                        <Markdown 
                            components={ChakraUIRenderer(documentSummaryMDTheme)} 
                            skipHtml
                            
                        >       
                                {documentSummaryResponse.markdown_content? documentSummaryResponse.markdown_content:'파일 내용이 들어갈 예정입니다.'}                                   
                        </Markdown>
                        </Box> 
                    </Container>
                )}
            </HStack>
        </Container>
    )
}

export default PlaygroundPage;